
import { Route, Routes } from 'react-router-dom';
import { Landing } from './landing';
import { Suspense } from 'react';
import { Careers } from './careers';

function App() {
  return (
      <Routes>
        <Route path="/" element={Landing()} />
        <Route path="/career" element={<Suspense fallback={null}><Careers /></Suspense>}></Route>
      </Routes>
  );
}

export default App;
