
interface IJob{
    title: string;
    dec: string;
    uri: string;
}

export const jobList: IJob[] = [
    {
        title: "Business Development Manager",
        dec: "Full-time, Remote",
        uri: "https://questlabs.typeform.com/bd-manager"
    },
    {
        title: "AI/ML Product Manager",
        dec: "Full-time, On-site",
        uri: "https://questlabs.typeform.com/ai-pm"
    },
    {
        title: "UX Designer",
        dec: "Full-time, Remote",
        uri: "https://questlabs.typeform.com/productdesigner "
    },
    {
        title: "Account Manager",
        dec: "Full-time, Remote",
        uri: "https://questlabs.typeform.com/account-manager"
    },
    {
        title: "ML Research Intern",
        dec: "Full-time, On-site",
        uri: "https://questlabs.typeform.com/ml-intern"
    },
    {
        title: "Marketing Manager",
        dec: "Full-time, Remote",
        uri: "https://questlabs.typeform.com/marketing"
    },
    {
        title: "Content Strategist",
        dec: "Part-time, Remote",
        uri: "https://questlabs.typeform.com/contentstrategy"
    },
    {
        title: "UI Designer",
        dec: "Full-time, On-site",
        uri: "https://questlabs.typeform.com/ui-designer"
    },
    {
        title: "Growth Manager",
        dec: "Full-time, Remote",
        uri: "https://questlabs.typeform.com/growth-manager"
    }
] 