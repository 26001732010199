
export function AboutUs() {
    return (
        <section className="flex flex-col px-[90px] md:px-[18px]">
            <span className="text-6xl md:text-[40px] text-white mb-[30px]" style={{fontFamily: 'CircularStd'}}>About us</span>
            <span className="text-xl text-white/[.7] mb-[117px]">
                Founded by top researchers at renowned universities, we are a research and development company<br />
                on a mission to redefine human-AI collaboration. Backed by visionaries such as Polychain Capital,<br />
                Sequoia Capital, Matrix Partners, Samsung, and other top-tier investors, our goal is to leverage the<br />
                most disruptive technology to shape the future of collaborative intelligence.
            </span>
        </section>
    )
}