import { isMobile } from "react-device-detect"
import { useNavigate } from "react-router-dom";

export function JoinQuest() {

    const navigate = useNavigate();
    
    const seeMore = () => {
        document.getElementById('careea')?.scrollIntoView()
    }

    return (
        <section className={`w-full h-full pt-[60px] pb-[57px] px-[90px] md:px-[18px] md:pt-[18px] flex flex-col justify-between`}>
            {
                !isMobile && <img src="/images/quest-labs.svg" className="w-[120px] h-5 cursor-pointer" alt="" onClick={() => navigate(-1)} />
            }
            {
                isMobile && <div className="flex justify-between">
                    <img src="/images/arrow-right.svg" className="rotate-180" onClick={() => {
                        navigate(-1);
                    }} alt="" />
                    <img src="/images/quest-labs.svg" className="w-[120px] h-5" alt="" />
                    <span></span>
                </div>
            }
            <div className="flex items-end justify-between">
                <div className="flex flex-col">
                    <span className="text-[90px] md:text-[60px] text-white" style={{fontFamily: 'CircularStd'}}>Join Quest</span>
                    <span className="text-xl md:text-[20px] text-white/[.7]">Join our global team on a mission to redefine human-ai collaboration.</span>
                    {
                        isMobile && <img src="/images/see-more.svg" width={60} height={60} alt="" className="cursor-pointer mt-5" onClick={() => {
                            seeMore()
                        }} />
                    } 
                </div>
                {
                    !isMobile && <img src="/images/see-more.svg" width={60} height={60} alt="" className="cursor-pointer" onClick={() => {
                        seeMore()
                    }} />
                } 
            </div>
        </section>
    )
}