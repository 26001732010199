import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

export function Landing() {

    const navigate = useNavigate();
    
    return (
        <div className={`w-full h-full bg-cover ${isMobile ? 'bg-landBgMobile' : 'bg-landBg'}`}>
            <section className={`w-full h-full flex flex-col pt-[60px] md:pt-[40px] pb-[70px] md:pb-[50px] justify-between items-center bg-gradient`}>
                <img src="/images/quest-labs.svg" className="w-[140px] md:w=[100px] h-6 md:h-[18px]" alt="" />
                <div className="flex flex-col items-center">
                    {
                        !isMobile && <span className="text-center mb-10 text-[46px]" style={{fontFamily: 'CircularStd'}}>We are on a mission to redefine<br />human-AI collaboration</span>
                    }
                    {
                        isMobile && <span className="text-center mb-10 text-3xl" style={{fontFamily: 'CircularStd'}}>We are on a mission<br />to redefine human-AI<br />collaboration</span>
                    }
                    <div onClick={() => {
                        window.open("https://form.typeform.com/to/Dc7Vo04m");
                    }} className="flex justify-center items-center text-lg md:text-sm w-[160px] h-[44px] md:w-[126px] md:h-[36px] rounded-[30px] border-solid border border-white cursor-pointer">Learn more</div>
                </div>
                <div className="flex">
                    <span className="pr-5 border-r border-solid border-white text-lg md:text-sm cursor-pointer" onClick={() => {
                        navigate('/career');
                    }}>Careers</span>
                    <div className="flex pl-5 gap-3 items-center">
                        <span className="text-[#B0B0B0] text-lg md:text-sm">Follow us:</span>
                        <img
                            className="cursor-pointer w-[20px] h-[20px] md:w-[16px] md:h-[16px]"
                            onClick={() => {
                                window.open("https://www.linkedin.com/company/quest-innovation-labs/")
                            }}
                            src="/images/link-in.svg"
                        />
                        <img
                            className="cursor-pointer w-[20px] h-[20px] md:w-[16px] md:h-[16px]"
                            onClick={() => {
                                window.open("https://twitter.com/QuestLabsAI")
                            }}
                            src="/images/twitter.svg"
                        />
                    </div>
                </div>
            </section>
        </div>
    )
}