import { isMobile } from "react-device-detect";
import { JoinQuest } from "./components/joinQuest";
import { AboutUs } from "./components/aboutUs";
import { Jobs } from "./components/jobs";

export function Careers() {
    return (
        <div className={`w-full h-full bg-cover ${isMobile ? 'bg-landBgMobile' : 'bg-landBg'}`}>
            <section className="w-full h-full bg-gardient1">
                <JoinQuest />
                <div id="careea" className="w-full bg-black pt-[90px] pb-[117px]">
                    <AboutUs />
                    <Jobs />
                </div>
            </section>
        </div>
    );
}