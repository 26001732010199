import { isMobile } from "react-device-detect";
import { jobList } from "../utils/utils";

export function Jobs() {

    const goToUri = (uri: string) => {
        window.open(uri,'_blank');
    }

    return (
        <section className="flex flex-col px-[90px] md:px-[18px]">
            <span className="text-6xl md:text-[40px] text-white mb-[30px]" style={{ fontFamily: 'CircularStd' }}>Careers</span>
            <div className={`grid gap-[10px] ${isMobile ? '' : 'grid-cols-[repeat(auto-fill,413px)]'}`}>
                {
                    !isMobile && jobList.map((item, index) => {
                        return <div className="flex flex-col h-[260px] justify-between bg-[#15151A] rounded-[15px] px-[25px] py-[25px] cursor-pointer" onClick={() => {
                            goToUri(item.uri);
                        }} key={index}>
                            <div className="flex flex-col">
                                <span className="text-[25px] text-white">{item.title}</span>
                                <span className="text-lg text-white/[.6]">{item.dec}</span>
                            </div>
                            <div className="flex">
                                <span className="mr-1">Learn More</span>
                                <img src="/images/arrow-right.svg" alt="" />
                            </div>
                        </div>
                    })
                }
                {
                    isMobile && jobList.map((item, index) => {
                        return <div className="flex flex-col h-[140px] justify-between bg-[#15151A] rounded-[15px] px-[25px] py-[25px] cursor-pointer" onClick={() => {
                            goToUri(item.uri);
                        }} key={index}>
                            <span className="text-[25px] text-white">{item.title}</span>
                            <div className="flex justify-between">
                                <span className="text-lg text-white/[.6]">{item.dec}</span>
                                <img src="/images/arrow-right.svg" alt="" />
                            </div>
                        </div>
                    })
                }                
            </div>
            <span className="text-lg text-white/[.6] mt-[60px] md:mt-[30px]">©2023. Quest Labs</span>
        </section>
    )
}